<template>
  <div class="tel">
    <div v-show="mygflg == true">
      <p class="tel_ttl">Inquiries regarding reservations and tours</p>
      <div class="telAndFax">
        <!-- <div class="backNone">
          <img src="../assets/img/telIcon.png" alt="tel" class="backNone_img" />
          <a href="tel:0223952865" class="backNone_num">+81-22-395-2865</a>
        </div> -->
        <div class="backNone">
          <img src="../assets/img/mailIcon_bk.png" alt="mail" class="backNone_img" />
          <a href="mailto:C9344801@nikkawhisky.co.jp" class="backNone_num">C9344801@nikkawhisky.co.jp</a>
        </div>
      </div>
      <!-- <div class="backRec">
        <p class="backRec_ttl">Phone reception hours are from 9:00 to 16:00 (excluding holidays).</p>
      </div> -->
    </div>
    <div v-show="mygflg == false">
      <p class="tel_ttl">Inquiries regarding reservations and tours</p>
      <div class="telAndFax">
        <!-- <div class="backNone">
          <img src="../assets/img/telIcon.png" alt="tel" class="backNone_img" />
          <a href="tel:0135233131" class="backNone_num">+81-135-23-3131</a>
        </div> -->
        <div class="backNone">
          <img src="../assets/img/mailIcon_bk.png" alt="mail" class="backNone_img" />
          <a href="mailto:yoichidistillery@nikkawhisky.co.jp" class="backNone_num">yoichidistillery@nikkawhisky.co.jp</a>
        </div>
      </div>
      <!-- <div class="backRec">
        <p class="backRec_ttl">Phone reception hours are from 9:00 to 16:15 (excluding holidays).</p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Tel",
  data() {
    return {
      mygflg: false,
    };
  },
  mounted() {
    // 宮城峡か否か判定する
    if (location.pathname.indexOf("miyagikyo") != -1) {
      this.mygflg = true;
    } else {
      this.mygflg = false;
    }

    var userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("safari") != -1 && userAgent.indexOf("chrome") == -1) {
      if (performance.navigation.type == 1) {
        localStorage.removeItem("courseInfo");
        localStorage.removeItem("selectCourseInfo");
        localStorage.removeItem("old_tel");
        localStorage.removeItem("password");
        localStorage.removeItem("resId");
        localStorage.removeItem("plantId");
        localStorage.removeItem("course_id");
        localStorage.removeItem("selectedDate");
        localStorage.removeItem("selectedTime");
        localStorage.removeItem("group_name");
        localStorage.removeItem("group_name_kana");
        // localStorage.removeItem('note');
        localStorage.removeItem("pref");
        localStorage.removeItem("tel");
        localStorage.removeItem("email");
        localStorage.removeItem("companion_name");
        localStorage.removeItem("companion_name_kana");
        localStorage.removeItem("count");
        localStorage.removeItem("total_num");
        // localStorage.removeItem('men');
        // localStorage.removeItem('women');
        localStorage.removeItem("adult_num");
        localStorage.removeItem("minor_num");
        localStorage.removeItem("visit");
        localStorage.removeItem("unit");
        localStorage.removeItem("selectedEvent");
        localStorage.removeItem("prefData");
        localStorage.removeItem("visitNum");
        localStorage.removeItem("comeWay");
        localStorage.removeItem("errs");
        localStorage.removeItem("allergy");
      }
    } else {
      window.$(window).on("beforeunload", () => {
        // ブラウザ閉じたときにまたはリロードしたときに
        localStorage.removeItem("courseInfo");
        localStorage.removeItem("selectCourseInfo");
        localStorage.removeItem("old_tel");
        localStorage.removeItem("password");
        localStorage.removeItem("resId");
        localStorage.removeItem("plantId");
        localStorage.removeItem("course_id");
        localStorage.removeItem("selectedDate");
        localStorage.removeItem("selectedTime");
        localStorage.removeItem("group_name");
        localStorage.removeItem("group_name_kana");
        // localStorage.removeItem('note');
        localStorage.removeItem("pref");
        localStorage.removeItem("tel");
        localStorage.removeItem("email");
        localStorage.removeItem("companion_name");
        localStorage.removeItem("companion_name_kana");
        localStorage.removeItem("count");
        localStorage.removeItem("total_num");
        // localStorage.removeItem('men');
        // localStorage.removeItem('women');
        localStorage.removeItem("adult_num");
        localStorage.removeItem("minor_num");
        localStorage.removeItem("visit");
        localStorage.removeItem("unit");
        localStorage.removeItem("selectedEvent");
        localStorage.removeItem("prefData");
        localStorage.removeItem("visitNum");
        localStorage.removeItem("comeWay");
        localStorage.removeItem("errs");
        localStorage.removeItem("allergy");
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tel {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding-bottom: 100px;
  color: #000;
  &_ttl {
    font-size: 26px;
    font-weight: bold;
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid #e8e8e8;
  }
}

// 768以下
.picxel .tel {
  width: calc(100% - 50px);
  padding-bottom: 96px;
  &_ttl {
    font-size: 22px;
    padding-bottom: 26px;
    margin-bottom: 24px;
  }
}

.telAndFax {
  display: flex;
  // align-items: center;
	flex-direction: column;
	row-gap: 20px;
}

// 768以下
.picxel .telAndFax {
  display: block;
}

.backNone {
  display: flex;
  align-items: center;
  // margin-right: 40px;
  &_img {
    width: 30px;
  }
  &_num {
    font-size: 36px;
    color: #000;
    font-weight: bold;
    margin-left: 10px;
    word-break: break-all;
  }
}

// 768以下
.picxel .backNone {
  margin: 0 0 13px 0;
  &_img {
    width: 30px;
    margin-right: 10px;
  }
  &_num {
    font-size: 20px;
  }
}

.backRec {
  &_ttl {
    font-size: 16px;
    margin-top: 18px;
  }
}

// 768以下
.picxel .backRec {
  &_ttl {
    font-size: 16px;
    margin-top: 17px;
  }
}

.sp {
  display: none;
}

// 768以下
.picxel .sp {
  display: block;
}
</style>
