<template>
    <div>
        <div class="backToTop">
            <a href="#">
                <img src="/common/img/icon_pagetop.svg" alt="">
                <span>TOP</span>
            </a>
        </div>

        <footer>
            <!-- 日本語footer要素 -->
            <template v-if="lang === 'jp'">
                <div class="inner">
                    <div class="footer_menu">
                    <div class="footer_menu_box" data-type="brands">
                        <div class="footer_menu_ttl"><a href="https://www.nikka.com/brands/"><p class="hover_txt">OUR BRANDS</p></a></div>
                        <ul>
                        <li><a href="https://www.nikka.com/products/malt/taketsuru/"><p class="hover_txt">竹鶴ピュアモルト</p></a></li>
                        <li><a href="https://www.nikka.com/products/malt/yoichi_miyagikyo/"><p class="hover_txt">シングルモルト余市/宮城峡</p></a></li>
                        <li><a href="https://www.nikka.com/products/blended/fromthebarrel/"><p class="hover_txt">フロム・ザ・バレル</p></a></li>
                        <li><a href="https://www.nikka.com/brands/"><p class="hover_txt">商品一覧</p></a></li>
                        </ul>
                    </div>
                    <div class="footer_menu_box" data-type="distilleries">
                        <div class="footer_menu_ttl"><a href="https://www.nikka.com/distilleries/"><p class="hover_txt">DISTILLERIES</p></a></div>
                        <ul>
                        <li><a href="https://www.nikka.com/distilleries/yoichi/"><p class="hover_txt">余市蒸溜所</p></a></li>
                        <li><a href="https://www.nikka.com/distilleries/miyagikyo/"><p class="hover_txt">宮城峡蒸溜所</p></a></li>
                        </ul>
                    </div>
                    <div class="footer_menu_box" data-type="story">
                        <div class="footer_menu_ttl"><a href="https://www.nikka.com/story/"><p class="hover_txt">STORY</p></a></div>
                        <ul>
                        <li><a href="https://www.nikka.com/story/founder/"><p class="hover_txt">創業者 竹鶴政孝</p></a></li>
                        <li><a href="https://www.nikka.com/story/history/"><p class="hover_txt">歴史</p></a></li>
                        <li><a href="https://www.nikka.com/story/awards/"><p class="hover_txt">受賞歴</p></a></li>
                        </ul>
                    </div>
                    <div class="footer_menu_box" data-type="no-child">
                        <div class="footer_menu_ttl" data-type="about"><a href="https://www.nikka.com/about/"><p class="hover_txt">ABOUT US</p></a></div>
                        <div class="footer_menu_ttl" data-type="contact"><a href="https://www.nikka.com/contact/"><p class="hover_txt">CONTACT US</p></a></div>
                    </div>
                    </div>

                    <div class="footer_sns">
                    <ul>
                        <li data-type="insta"><a href="https://www.instagram.com/nikkawhiskyglobal/" target="_blank"><img src="/common/img/icon_insta.svg" alt="Instagram"></a></li>
                        <li data-type="x"><a href="https://x.com/nikka_jp" target="_blank"><img src="/common/img/icon_x.svg" alt="X"></a></li>
                    </ul>
                    <p>※お酒にまつわる情報については、20歳未満の方への共有（シェア）はご遠慮ください。</p>
                    </div>
                </div>

                <div class="inner" data-type="wide">
                    <div class="footer_logo">
                    <svg data-type="emblem"><use xlink:href="#logo_emblem"></use></svg>
                    <svg data-type="text" aria-label="NIKKA WWHISKY"><use xlink:href="#logo_text"></use></svg>
                    </div>

                    <div class="footer_nav">
                    <ul>
                        <li data-type="sitemap"><a href="https://www.nikka.com/sitemap.html"><p class="hover_txt">SITEMAP</p></a></li>
                        <li data-type="policy"><a href="https://www.asahigroup-holdings.com/privacy_policy/" target="_blank"><p class="hover_txt">PRIVACY POLICY</p></a></li>
                        <li data-type="terms"><a href="https://www.asahigroup-holdings.com/privacy_policy/05/" target="_blank"><p class="hover_txt">ご利用規約</p></a></li>
                        <li data-type="webuse"><a href="https://www.asahibeer.co.jp/webuse/" target="_blank"><p class="hover_txt">推奨環境</p></a></li>
                    </ul>
                    <p class="footer_copyright">
                        © ASAHI BREWERIES, LTD. All rights reserved.<br data-view="sp">© THE NIKKA WHISKY DISTILLING Co., LTD. All rights reserved.
                    </p>
                    </div>
                </div>

                <div class="footer_attention">
                    <div data-type="stop"><img src="/common/img/icon_stop.svg" alt="STOP!20歳未満飲酒"></div>
                    <div data-type="alcohol"><img src="/common/img/icon_alcohol.svg" alt="お酒"></div>
                    <div data-type="recycle"><img src="/common/img/icon_recycle.svg" alt="のんだあとはリサイクル"></div>
                    <p>ストップ！20歳未満飲酒・飲酒運転。妊娠中や授乳期の飲酒は、<br>胎児・乳児の発育に悪影響を与えるおそれがあります。ほどよく、<br data-view="sp">楽しく、いいお酒。のんだあとはリサイクル。</p>
                </div>
            </template>

            <!-- 英語footer要素 -->
            <template v-else-if="lang === 'en'">
                <div class="inner">
                    <div class="footer_menu">
                    <div class="footer_menu_box" data-type="brands">
                        <div class="footer_menu_ttl"><a href="https://www.nikka.com/en/brands/"><p class="hover_txt">OUR BRANDS</p></a></div>
                        <ul>
                        <li><a href="https://www.nikka.com/eng/brands/taketsuru/"><p class="hover_txt">TAKETSURU PURE MALT</p></a></li>
                        <li><a href="https://www.nikka.com/eng/brands/singlemalt-yoichi/"><p class="hover_txt">YOICHI SINGLE MALT</p></a></li>
                        <li><a href="https://www.nikka.com/eng/brands/singlemalt-miyagikyo/"><p class="hover_txt">MIYAGIKYO SINGLE MALT</p></a></li>
                        <li><a href="https://www.nikka.com/eng/brands/fromthebarrel/"><p class="hover_txt">FROM THE BARREL</p></a></li>
                        <li><a href="https://www.nikka.com/en/brands/"><p class="hover_txt">ALL BRANDS</p></a></li>
                        </ul>
                    </div>
                    <div class="footer_menu_box" data-type="distilleries">
                        <div class="footer_menu_ttl"><a href="https://www.nikka.com/en/distilleries/"><p class="hover_txt">DISTILLERIES</p></a></div>
                        <ul>
                        <li><a href="https://www.nikka.com/en/distilleries/yoichi/"><p class="hover_txt">YOICHI DISTILLERY</p></a></li>
                        <li><a href="https://www.nikka.com/en/distilleries/miyagikyo/"><p class="hover_txt">MIYAGIKYO DISTILLERY</p></a></li>
                        </ul>
                    </div>
                    <div class="footer_menu_box" data-type="story">
                        <div class="footer_menu_ttl"><a href="https://www.nikka.com/en/story/"><p class="hover_txt">STORY</p></a></div>
                        <ul>
                        <li><a href="https://www.nikka.com/en/story/founder/"><p class="hover_txt">THE FOUNDER</p></a></li>
                        <li><a href="https://www.nikka.com/en/story/history/"><p class="hover_txt">HISTORY</p></a></li>
                        <li><a href="https://www.nikka.com/en/story/awards/"><p class="hover_txt">AWARDS</p></a></li>
                        </ul>
                    </div>
                    <div class="footer_menu_box" data-type="no-child">
                        <div class="footer_menu_ttl" data-type="about"><a href="https://www.nikka.com/en/about/"><p class="hover_txt">ABOUT US</p></a></div>
                        <div class="footer_menu_ttl" data-type="contact"><a href="https://www.nikka.com/en/contact/"><p class="hover_txt">CONTACT US</p></a></div>
                    </div>
                    </div>

                    <div class="footer_sns">
                    <ul>
                        <li data-type="insta"><a href="https://www.instagram.com/nikkawhiskyglobal/" target="_blank"><img src="/common/img/icon_insta.svg" alt="Instagram"></a></li>
                        <li data-type="x"><a href="https://x.com/nikka_jp" target="_blank"><img src="/common/img/icon_x.svg" alt="X"></a></li>
                    </ul>
                    <p>Do not share with minors.</p>
                    </div>
                </div>

                <div class="inner" data-type="wide">
                    <div class="footer_logo">
                    <svg data-type="emblem"><use xlink:href="#logo_emblem"></use></svg>
                    <svg data-type="text" aria-label="NIKKA WWHISKY"><use xlink:href="#logo_text"></use></svg>
                    </div>

                    <div class="footer_nav">
                    <ul>
                        <li data-type="sitemap"><a href="https://www.nikka.com/en/sitemap.html"><p class="hover_txt">SITEMAP</p></a></li>
                        <li data-type="policy"><a href="https://www.asahigroup-holdings.com/en/privacy_policy/" target="_blank"><p class="hover_txt">PRIVACY POLICY</p></a></li>
                        <li data-type="terms"><a href="https://www.asahigroup-holdings.com/en/privacy_policy/terms.html" target="_blank"><p class="hover_txt">Terms of Use</p></a></li>
                    </ul>
                    <p class="footer_copyright">
                        © ASAHI BREWERIES, LTD. All rights reserved.<br data-view="sp">© THE NIKKA WHISKY DISTILLING Co., LTD. All rights reserved.
                    </p>
                    </div>
                </div>

                <div class="footer_attention">
                    <!-- <div data-type="stop"><img src="/common/img/icon_stop.svg" alt="STOP!20歳未満飲酒"></div> -->
                    <!-- <div data-type="alcohol"><img src="/common/img/icon_alcohol.svg" alt="お酒"></div> -->
                    <!-- <div data-type="recycle"><img src="/common/img/icon_recycle.svg" alt="のんだあとはリサイクル"></div> -->
                    <p>Stop! Under-aged drinking and driving are prohibited by law.</p>
                </div>
            </template>
        </footer>
    </div>

</template>

<script>
export default {
    name: "MainFooter",
    props: {
        lang: {
            type: String,
            default: 'jp',
            required: true
        }
    },
    data() {
        return {
        };
    }
};
</script>
