<template>
  <div class="selectEvent">
    <div class="eventModal">
      <div class="pictureAll" v-show="modalItem.event_picture">
        <div class="pictureAll_conts">
          <p class="pictureAll_name" v-if="modalItem.plant_name" v-html="modalItem.plant_name"></p>
          <p class="pictureAll_ttl" v-show="modalItem.event_title" v-html="modalItem.event_title"></p>
          <div class="pictureAll_txt" v-show="modalItem.event_overview" v-html="modalItem.event_overview"></div>
        </div>
        <div class="pictureAll_img" v-show="modalItem.event_picture">
          <img :src="`/storage/${modalItem.event_picture}`" alt="" />
        </div>
      </div>
      <p class="eventModal_name" v-if="!modalItem.event_picture && modalItem.plant_name" v-html="modalItem.plant_name"></p>
      <section class="eventSection">
        <h2 class="eventSection_none" v-show="modalItem.none" v-html="modalItem.none"></h2>
        <h2 class="eventSection_ttl" v-show="!modalItem.event_picture && modalItem.event_title" v-html="modalItem.event_title"></h2>
        <div class="eventSection_txt" v-show="!modalItem.event_picture && modalItem.event_overview" v-html="modalItem.event_overview"></div>
        <ul class="eventContents" v-show="modalItem.event_title">
          <li class="eventContent" v-show="modalItem.event_hall_name">
            <h3 class="eventContent_ttl">Venue</h3>
            <p class="eventContent_cont" v-html="modalItem.event_hall_name"></p>
          </li>
          <li class="eventContent" v-show="modalItem.event_date">
            <h3 class="eventContent_ttl">Date</h3>
            <p class="eventContent_cont" v-html="modalItem.event_date"></p>
          </li>
          <li class="eventContent" v-show="modalItem.event_required_time">
            <h3 class="eventContent_ttl">Duration</h3>
            <div class="eventContent_cont" v-html="modalItem.event_required_time"></div>
          </li>
          <!-- <li class="eventContent" v-show="modalItem.event_capacity">
							<h3 class="eventContent_ttl">Non-eligible</h3>
							<div class="eventContent_cont" v-html="modalItem.event_capacity"></div>
						</li> -->
          <li class="eventContent" v-show="modalItem.event_target">
            <h3 class="eventContent_ttl">Non-eligible</h3>
            <div class="eventContent_cont" v-html="modalItem.event_target"></div>
          </li>
          <li class="eventContent" v-show="modalItem.event_detail">
            <h3 class="eventContent_ttl">Content</h3>
            <div class="eventContent_cont" v-html="modalItem.event_detail"></div>
          </li>
          <li class="eventContent" v-show="modalItem.event_fee">
            <h3 class="eventContent_ttl">Fee</h3>
            <div class="eventContent_cont" v-html="modalItem.event_fee"></div>
          </li>
        </ul>
        <button type="button" class="eventSection_select" v-show="modalItem.plant_name" @click="selectCourse()">Select</button>
        <!-- <button type="button" class="eventSection_btn" @click="closeEvent()">戻る</button> -->
        <router-link tag="a" :to="{ path: '/eng/miyagikyo/reservation', query: { date: selectedDate } }" class="eventSection_btn" v-show="plantId == 1000">Back<span class="dateLink_arrow"></span></router-link>
        <router-link tag="a" :to="{ path: '/eng/yoichi/reservation', query: { date: selectedDate } }" class="eventSection_btn" v-show="plantId == 1001">Back<span class="dateLink_arrow"></span></router-link>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "selectEvent",
  data() {
    return {
      mygflg: false,
      plantId: 0,
      plantName: "",
      modalItem: [],
      selectId: null,
      selectTime: null,
      defaultTime: null,
      selectedDate: localStorage.getItem("selectedDate"),
      courseInfo: JSON.parse(localStorage.getItem("courseInfo")),
    };
  },
  head: {
    title: function () {
      // 余市か宮城峡か判定
      if (location.pathname.indexOf("miyagikyo") != -1) {
        return {
          inner: 'RESERVATIONS｜MIYAGIKYO DISTILLERY｜NIKKA WHISKY',
          separator: ' ',
        }
      } else {
        return {
          inner: 'RESERVATIONS｜YOICHI DISTILLERY｜NIKKA WHISKY',
          separator: ' ',
        }
      }
    },
    meta: function () {
			return [
				{ property: 'og:locale', content: 'en_US' },
				{ property: 'og:site_name', content: 'NIKKA WHISKY' },
			]
		}
  },
  mounted() {
    // 宮城峡か否か判定する
    if (location.pathname.indexOf("miyagikyo") != -1) {
      this.mygflg = true;
      this.plantId = 1000;
      this.plantName = "Miyagikyo distillery";
    } else {
      this.mygflg = false;
      this.plantId = 1001;
      this.plantName = "Yoichi distillery";
    }

    this.selectId = this.$route.query.event_id;
    this.defaultTime = this.$route.query.time;
    this.selectTime = this.retakeDate(this.defaultTime);

    window.scrollTo(0, 0);

    var hit = 0;
    // 一致するコースIDがあるかどうか見る
    for (var c = 0; c < this.courseInfo.length; c++) {
      if (this.courseInfo[c].course_id == this.selectId) {
        hit++;
        this.modalItem = [];
        this.modalItem.selectId = this.courseInfo[c].course_id;
        this.modalItem.plant_name = this.plantName;
        this.modalItem.event_title = this.courseInfo[c].name;
        this.modalItem.event_picture = this.courseInfo[c].image;
        this.modalItem.event_overview = this.courseInfo[c].overview;
        this.modalItem.event_hall_name = this.courseInfo[c].place;
        this.modalItem.event_date = this.courseInfo[c].course_date;
        this.modalItem.event_required_time = this.courseInfo[c].total_time;
        this.modalItem.event_capacity = this.courseInfo[c].capacity;
        this.modalItem.event_target = this.courseInfo[c].target;
        this.modalItem.event_detail = this.courseInfo[c].detail;
        this.modalItem.event_fee = this.courseInfo[c].fee;
        // this.modalItem.event_inquiry = res.data.data.course_info[c].event_inquiry;
      }
    }

    if (hit == 0) {
      this.modalItem = [];
      this.modalItem.none = "コースのデータが存在しません。";
    }
  },
  methods: {
    retakeDate(date) {
      var slice = Number(date);
      var toString = String(slice);
      var slicer = ":" + toString.slice(-2) + "～";
      var result = toString.slice(0, -2) + slicer;
      return result;
    },

    selectCourse() {
      // コースを選択したら入力画面へ移動
      if (this.mygflg == true) {
        this.$router.push({ path: "/eng/miyagikyo/reservation/form" });
      } else {
        this.$router.push({ path: "/eng/yoichi/reservation/form" });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.eventModal {
  width: 100%;
  padding: 60px 0 80px;
  &_name {
    width: 100px;
    text-align: center;
    font-size: 16px;
    line-height: 1;
    color: #000;
    border: 1px solid #000;
    padding: 6px;
    margin: 0 auto 15px;
    transform: rotate(0.03deg);
    -moz-transform: rotate(0.03deg);
    -ms-transform: rotate(0.03deg);
    -o-transform: rotate(0.03deg);
    -webkit-transform: rotate(0.03deg);
  }
}

// 768以下
.picxel .eventModal {
  &_name {
    width: 90px;
    font-size: 13px;
    padding: 4px 0;
    margin-bottom: 10px;
  }
}

.pictureAll {
  width: calc(100% - 30px);
  max-width: 1010px;
  margin: 0 auto 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &_conts {
    width: calc(40% - 20px);
  }
  &_name {
    // width: 100px;
    width: auto;
    text-align: center;
    font-size: 16px;
    line-height: 1;
    color: #000;
    border: 1px solid #000;
    padding: 6px;
    margin: 0 auto 43px;
    transform: rotate(0.03deg);
    -moz-transform: rotate(0.03deg);
    -ms-transform: rotate(0.03deg);
    -o-transform: rotate(0.03deg);
    -webkit-transform: rotate(0.03deg);
  }
  &_ttl {
    font-size: 16px;
    margin-top: 10px;
    transform: rotate(0.03deg);
    -moz-transform: rotate(0.03deg);
    -ms-transform: rotate(0.03deg);
    -o-transform: rotate(0.03deg);
    -webkit-transform: rotate(0.03deg);
  }
  &_txt {
    font-size: 16px;
    margin-bottom: 20px;
    transform: rotate(0.03deg);
    -moz-transform: rotate(0.03deg);
    -ms-transform: rotate(0.03deg);
    -o-transform: rotate(0.03deg);
    -webkit-transform: rotate(0.03deg);
  }
  &_img {
    width: calc(60% - 50px);
    img {
      width: 100%;
    }
  }
}

// 768以下
.picxel .pictureAll {
  width: calc(100% - 50px);
  display: block;
  &_conts {
    width: 100%;
  }
  &_name {
    width: 90px;
    font-size: 13px;
    padding: 4px 0;
    margin-bottom: 14px;
  }
  &_ttl {
    font-size: 12px;
    margin: 7px 0 20px;
  }
  &_txt {
    font-size: 12px;
    margin-bottom: 20px;
  }
  &_img {
    width: 100%;
  }
}

.eventSection {
  width: calc(100% - 100px);
  max-width: 900px;
  margin: 0 auto;
  &_none {
    font-size: 26px;
    text-align: center;
    margin-bottom: 20px;
    transform: rotate(0.03deg);
    -moz-transform: rotate(0.03deg);
    -ms-transform: rotate(0.03deg);
    -o-transform: rotate(0.03deg);
    -webkit-transform: rotate(0.03deg);
  }
  &_ttl {
    width: 100%;
    font-size: 26px;
    text-align: center;
    margin: 0 auto 9px;
    transform: rotate(0.03deg);
    -moz-transform: rotate(0.03deg);
    -ms-transform: rotate(0.03deg);
    -o-transform: rotate(0.03deg);
    -webkit-transform: rotate(0.03deg);
  }
  &_txt {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
    transform: rotate(0.03deg);
    -moz-transform: rotate(0.03deg);
    -ms-transform: rotate(0.03deg);
    -o-transform: rotate(0.03deg);
    -webkit-transform: rotate(0.03deg);
  }
  &_select {
    display: block;
    position: relative;
    width: 320px;
    color: #fff;
    background-color: #000;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    border: none;
    outline: none;
    padding: 21px 0;
    margin: 40px auto 20px;
    cursor: pointer;
    transform: rotate(0.03deg);
    -moz-transform: rotate(0.03deg);
    -ms-transform: rotate(0.03deg);
    -o-transform: rotate(0.03deg);
    -webkit-transform: rotate(0.03deg);
    &::after {
      content: "";
      width: 7px;
      height: 7px;
      border: 0px;
      border-top: solid 2px #fff;
      border-right: solid 2px #fff;
      position: absolute;
      top: 50%;
      right: 30px;
      transform: rotate(45deg) translateY(-50%);
      margin-top: -2px;
    }
  }
  &_btn {
    display: block;
    width: 230px;
    font-size: 14px;
    text-align: center;
    color: #000;
    background-color: #fff;
    border: 1px solid #000;
    margin: 0 auto;
    padding: 16px 0;
    outline: none;
    transform: rotate(0.03deg);
    -moz-transform: rotate(0.03deg);
    -ms-transform: rotate(0.03deg);
    -o-transform: rotate(0.03deg);
    -webkit-transform: rotate(0.03deg);
    cursor: pointer;
  }
}

// 768以下
.picxel .eventSection {
  &_none {
    font-size: 20px;
    margin-bottom: 20px;
  }
  &_ttl {
    width: calc(100% - 30px);
    font-size: 20px;
    margin-bottom: 12px;
  }
  &_txt {
    text-align: left;
    font-size: 12px;
    margin-bottom: 20px;
  }
  &_select {
    width: 100%;
    font-size: 16px;
    padding: 16px 0;
    margin: 20px auto 15px;
    &::after {
      width: 7px;
      height: 7px;
    }
  }
  &_btn {
    font-size: 12px;
    width: 160px;
    padding: 13px 0;
  }
}

// イベントモーダルの詳細
.eventContents {
  width: 100%;
  border-bottom: 1px solid #000;
}

.eventContent {
  list-style: none;
  border-top: 1px solid #000;
  display: flex;
  padding: 26px 0;
  flex-wrap: nowrap;
  column-gap: 20px;
  &_ttl {
    width: 170px;
    font-size: 16px;
    line-height: 1.75;
    padding-left: 16px;
    padding-left: 0;
    box-sizing: border-box;
    transform: rotate(0.03deg);
    -moz-transform: rotate(0.03deg);
    -ms-transform: rotate(0.03deg);
    -o-transform: rotate(0.03deg);
    -webkit-transform: rotate(0.03deg);
  }
  &_cont {
    width: calc(100% - 150px);
    font-size: 16px;
    transform: rotate(0.03deg);
    -moz-transform: rotate(0.03deg);
    -ms-transform: rotate(0.03deg);
    -o-transform: rotate(0.03deg);
    -webkit-transform: rotate(0.03deg);
    line-height: 1.75 !important;
    p[style="color:red"] {
      font-size: 14px;
    }
  }

  .prohibited {
    margin: 16px 0 0 150px;
    &__list {
      display: flex;
      gap: 0 12px;
    }
    &__item {
    }
  }
}

// 768以下
.picxel .eventContent {
  padding: 15px 0;
  &_ttl {
    width: 100px;
    font-size: 12px;
    padding-left: 0;
  }
  &_cont {
    width: calc(100% - 100px);
    font-size: 12px;
  }
  .prohibited {
    margin: 16px 0 0 100px;
  }
}
</style>
