<template>
  <div class="stepContent">
    <ul class="formSteps">
      <li class="step stepOne">
        <p class="step_ttl">Select desired date</p>
        <!-- <p class="step_cont">見学希望日選択</p> -->
      </li>
      <li class="step stepTwo">
        <p class="step_ttl">Input information</p>
        <!-- <p class="step_cont">お客様情報入力</p> -->
      </li>
      <li class="step stepThree">
        <p class="step_ttl">Confirmation</p>
        <!-- <p class="step_cont">お客様情報確認</p> -->
      </li>
      <li class="step stepFour">
        <p class="step_ttl">Reservation complete</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Step",
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
// ステップ
.formSteps {
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  width: 100%;
  list-style: none;
  margin: 0 auto;
  column-gap: 40px;
}

// 768以下
.picxel .formSteps {
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 22px;
}

.step {
  text-align: center;
  color: #fff;
  width: calc(100% / 4 - 30px);
  opacity: 0.3;
  &_ttl {
    justify-content: center;
    height: 100%;
    position: relative;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.05em;
    border-radius: 4px;
    // background-color: #D5D5D5;
    // border: 1px solid #000;
    padding: 7px 19px 10px;
    // color: #000;
    display: flex;
    align-items: center;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -34px;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      border-left: 10px solid #000;
      border-top: 7.5px solid transparent;
      border-bottom: 7.5px solid transparent;
    }
  }
  // &_cont {
  // 	font-size: 16px;
  // 	text-align: center;
  // 	letter-spacing: 0.075em;
  // 	font-weight: bold;
  // }
}

.miyagi .step {
  &_ttl {
    background-color: #427d46;
    border: 1px solid #cae3c3;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -32px;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      border-left: 10px solid #427d46;
      border-top: 7.5px solid transparent;
      border-bottom: 7.5px solid transparent;
    }
  }
}

.yoichi .step {
  &_ttl {
    background-color: #3c6faf;
    border: 1px solid #f8e5d4;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -32px;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      border-left: 10px solid #3c6faf;
      border-top: 7.5px solid transparent;
      border-bottom: 7.5px solid transparent;
    }
  }
}

// 768以下
.picxel .step {
  // width: calc(100% / 4 - 15px);
  width: 100%;
  &_ttl {
    font-size: 14px;
    padding: 5px 0;
    border-radius: 4px;
    display: block;
    text-align: center;
    &::after {
      content: "";
      position: absolute;
      right: -21px;
      // border-left: 10px solid #d5d5d5;
      border-top: 7.5px solid transparent;
      border-bottom: 7.5px solid transparent;
      top: auto;
      bottom: -18px;
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      transform: rotate(90deg);
    }
  }
  &_cont {
    text-align: left;
    font-size: 13px;
  }
}

.stepOne {
  .step_ttl {
    // background-color: #000;
    color: #fff;
    &::after {
      // border-left: 10px solid #000;
      border-top: 7.5px solid transparent;
      border-bottom: 7.5px solid transparent;
    }
  }
}
.stepFour {
  .step_ttl {
    &::after {
      display: none;
    }
  }
}

// お客様情報入力の画面時
.twoStep {
  .step_ttl {
    border: 1px solid #000;
  }
  .stepOne {
    .step_ttl {
      background-color: #000;
      color: #fff;
      &::after {
        border-left: 10px solid #000;
        border-top: 7.5px solid transparent;
        border-bottom: 7.5px solid transparent;
      }
    }
  }
  .stepTwo {
    .step_ttl {
      background-color: #000;
      color: #fff;
      &::after {
        border-left: 10px solid #000;
        border-top: 7.5px solid transparent;
        border-bottom: 7.5px solid transparent;
      }
    }
  }
  .stepThree {
    .step_ttl {
      color: #000;
    }
  }
  .stepFour {
    .step_ttl {
      color: #000;
    }
  }
}

// お客様情報確認の画面時
.threeStep {
	.step{
		opacity: 1;
	}
  .step_ttl {
    border: 1px solid #000;
  }
  .stepOne {
    .step_ttl {
      background-color: #000;
      color: #fff;
      &::after {
        border-left: 10px solid #000;
        border-top: 7.5px solid transparent;
        border-bottom: 7.5px solid transparent;
      }
    }
  }
  .stepTwo {
    .step_ttl {
      background-color: #000;
      color: #fff;
      &::after {
        border-left: 10px solid #000;
        border-top: 7.5px solid transparent;
        border-bottom: 7.5px solid transparent;
      }
    }
  }
  .stepThree {
    .step_ttl {
      background-color: #000;
      color: #fff;
      &::after {
        border-left: 10px solid #000;
        border-top: 7.5px solid transparent;
        border-bottom: 7.5px solid transparent;
      }
    }
  }
  .stepFour {
    .step_ttl {
      color: #000;
    }
  }
}

// 予約完了の画面時
.fourStep {
  .stepOne {
    .step_ttl {
      background-color: #af9754;
      &::after {
        border-left: 10px solid #af9754;
        border-top: 7.5px solid transparent;
        border-bottom: 7.5px solid transparent;
      }
    }
  }
  .stepTwo {
    .step_ttl {
      background-color: #af9754;
      &::after {
        border-left: 10px solid #af9754;
        border-top: 7.5px solid transparent;
        border-bottom: 7.5px solid transparent;
      }
    }
  }
  .stepThree {
    .step_ttl {
      background-color: #af9754;
      &::after {
        border-left: 10px solid #af9754;
        border-top: 7.5px solid transparent;
        border-bottom: 7.5px solid transparent;
      }
    }
  }
  .stepFour {
    .step_ttl {
      background-color: #af9754;
    }
  }
}

.sp {
  display: none;
}

.picxel .sp {
  display: block;
}

.stepContent {
  &.oneStep {
    .step.stepOne {
      opacity: 1;
    }
  }

  &.twoStep {
    .step {
      opacity: 1;
    }
    .step.stepTwo {
    }
  }

  &.threeStep {
    .step.stepThree {
      opacity: 1;
    }
  }
}
</style>
