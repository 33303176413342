<template>
	<div class="tel">
		<div v-show="mygflg == true">
			<p class="tel_ttl">Inquiries regarding <br class="sp">reservations and tours</p>
			<div class="telAndFax">
				<!-- <div class="backWhite">
					<img src="../assets/img/telIcon.png" alt="tel" class="backWhite_img">
					<a href="tel:0223952865" class="backWhite_num">+81-22-395-2865</a>
				</div> -->
				<div class="backWhite">
					<img src="../assets/img/mailIcon_bk.png" alt="email" class="backWhite_img">
					<a href="mailto:C9344801@nikkawhisky.co.jp" class="backWhite_num">C9344801@nikkawhisky.co.jp</a>
				</div>
			</div>
			<!-- <div class="backRec">
				<p class="backRec_ttl">Phone reception hours are from 9:00 to 16:00 (excluding holidays).</p>
			</div> -->
		</div>
		<div v-show="mygflg == false">
			<p class="tel_ttl">Inquiries regarding <br class="sp">reservations and tours</p>
			<div class="telAndFax">
				<!-- <div class="backWhite">
					<img src="../assets/img/telIcon.png" alt="tel" class="backWhite_img">
					<a href="tel:0135233131" class="backWhite_num">+81-135-23-3131</a>
				</div> -->
				<div class="backWhite">
					<img src="../assets/img/mailIcon_bk.png" alt="email" class="backWhite_img">
					<a href="mailto:yoichidistillery@nikkawhisky.co.jp" class="backWhite_num">yoichidistillery@nikkawhisky.co.jp</a>
				</div>
			</div>
			<!-- <div class="backRec">
				<p class="backRec_ttl">Phone reception hours are from 9:15 to 16:15 (excluding holidays).</p>
			</div> -->
		</div>
	</div>
</template>

<script>
export default {
	name: 'Tel',
	data () {
		return {
			mygflg: false
		}
	},
	mounted() {
		// 宮城峡か否か判定する
		if(location.pathname.indexOf('miyagikyo') != -1) {
			this.mygflg = true;
		}else {
			this.mygflg = false;
		}

		var userAgent = window.navigator.userAgent.toLowerCase();
		if(userAgent.indexOf('safari') != -1 && userAgent.indexOf('chrome') == -1) {
			if(performance.navigation.type == 1) {
				localStorage.removeItem('courseInfo');
				localStorage.removeItem('selectCourseInfo');
				localStorage.removeItem('old_tel');
				localStorage.removeItem('password');
				localStorage.removeItem('resId');
				localStorage.removeItem('plantId');
				localStorage.removeItem('course_id');
				localStorage.removeItem('selectedDate');
				localStorage.removeItem('selectedTime');
				localStorage.removeItem('group_name');
				localStorage.removeItem('group_name_kana');
				// localStorage.removeItem('note');
				localStorage.removeItem('pref');
				localStorage.removeItem('tel');
				localStorage.removeItem('email');
				localStorage.removeItem('companion_name');
				localStorage.removeItem('companion_name_kana');
				localStorage.removeItem('count');
				localStorage.removeItem('total_num');
				// localStorage.removeItem('men');
				// localStorage.removeItem('women');
				localStorage.removeItem('adult_num');
				localStorage.removeItem('minor_num');
				localStorage.removeItem('visit');
				localStorage.removeItem('unit');
				localStorage.removeItem('selectedEvent');
				localStorage.removeItem('prefData');
				localStorage.removeItem('visitNum');
				localStorage.removeItem('comeWay');
				localStorage.removeItem('errs');
				localStorage.removeItem('allergy');
			}
		}else {
			window.$(window).on('beforeunload', () => {
				// ブラウザ閉じたときにまたはリロードしたときに
				localStorage.removeItem('courseInfo');
				localStorage.removeItem('selectCourseInfo');
				localStorage.removeItem('old_tel');
				localStorage.removeItem('password');
				localStorage.removeItem('resId');
				localStorage.removeItem('plantId');
				localStorage.removeItem('course_id');
				localStorage.removeItem('selectedDate');
				localStorage.removeItem('selectedTime');
				localStorage.removeItem('group_name');
				localStorage.removeItem('group_name_kana');
				// localStorage.removeItem('note');
				localStorage.removeItem('pref');
				localStorage.removeItem('tel');
				localStorage.removeItem('email');
				localStorage.removeItem('companion_name');
				localStorage.removeItem('companion_name_kana');
				localStorage.removeItem('count');
				localStorage.removeItem('total_num');
				// localStorage.removeItem('men');
				// localStorage.removeItem('women');
				localStorage.removeItem('adult_num');
				localStorage.removeItem('minor_num');
				localStorage.removeItem('visit');
				localStorage.removeItem('unit');
				localStorage.removeItem('selectedEvent');
				localStorage.removeItem('prefData');
				localStorage.removeItem('visitNum');
				localStorage.removeItem('comeWay');
				localStorage.removeItem('errs');
				localStorage.removeItem('allergy');
			});
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.tel {
		width: 800px;
		margin: 0 auto 100px;
		border: 1px solid #ebebeb;
		background-color: #fff;
		&_ttl {
			font-size: 18px;
			text-align: center;
			font-weight: bold;
			background-color: #808080;
			padding: 15px 0;
			color: #fff;
		}
	}

	// 768以下
	.picxel .tel {
		width: calc(100% - 50px);
		margin-bottom: 100px;
		&_ttl {
			font-size: 14px;
			padding: 15px 0;
		}
	}

	.telAndFax {
		display: flex;
		// justify-content: space-between;
		// justify-content: center;
		flex-direction: column;
		row-gap: 20px;
		// column-gap: 60px;
		width: 460px;
		width: auto;
		// margin: 20px auto 0;
		padding: 20px;

	}

	// 768以下
	.picxel .telAndFax {
		display: block;
		// width: 100%;
		width: auto;
		display: flex;
		flex-direction: column;
		row-gap: 10px;
		// margin-top: 20px;
		
	}

	.backWhite {
		// width: calc(100% / 2 - 20px);
		width: auto;
		display: flex;
		justify-content: center;
		// justify-content: space-between;
		align-items: center;
		// margin: 10px auto;
		// padding: 20px 0;
		&_img {
			width: 30px;
			margin-right: 10px;
		}
		&_num {
			font-size: 26px;
			font-weight: bold;
			text-decoration: none;
			color: #000;
			word-break: break-all;
		}
	}

	// 768以下
	.picxel .backWhite {
		// width: 186px;
		width: auto;
		// margin: 10px auto;
		padding: 0;
		margin: 0 auto;
		&_img {
			width: 27px;
			height: 27px;
		}
		&_num {
			font-size: 23px;
		}
	}

	.backRec {
		// width: 390px;
		width: auto;
		text-align: center;
		// margin: 20px auto 10px;
		margin: 0 auto;
		padding: 20px 0;
		font-size: 18px;
		&_ttl {
			span {
				margin-left: 10px;
			}
		}
	}

	// 768以下
	.picxel .backRec {
		width: 190px;
		width: auto;
		margin-bottom: 10px;
		font-size: 14px;
		text-align: center;
		line-height: 1.6 !important;
		&_ttl {
			span {
				margin-left: 10px;
			}
		}
	}
</style>