<template>
	<div class="result">
		<Step class="fourStep" />
		<h2 class="result_ttl" v-html="ttlMessage"></h2>
		<div class="resultBox" v-if="reserve_flg == true">
			<p class="resultBox_careful">Please make sure to keep your “Reservation Number” as it is necessary for confirmation, changes, and cancellations.</p>
			<ul>
				<li v-show="mygflg == true && this.reserve_flg == true" class="resultRice">
					*Changes and cancellations regarding the date, time, and number of people via the internet can be made until 12:00 PM(noon) the day before the tour.<br>
					Please make any changes or cancellations through the reservation change/cancellation button on the reservation application page.
				</li>
				<li v-show="mygflg == false && this.reserve_flg == true" class="resultRice">
					*Changes and cancellations regarding the date, time, and number of people via the internet can be made until 12:00 PM(noon) the day before the tour.<br>
					Please make any changes or cancellations through the reservation change/cancellation button on the reservation application page.
				</li>
				<li class="resultList">
					<p class="resultList_ttl">Reservation Number</p>
					<p class="resultList_txt">{{ password }}</p>
				</li>
			</ul>
			<ul class="explanList">
				<li class="explanList_li">*In addition to the reservation details, we have sent a confirmation email to the registered email address with the subject “Confirmation Email”. Please check your email.</li>
				<li class="explanList_li">*If you wish to make changes or cancellations to your reservation, you can do so through the reservation change/cancellation button at the top of the reservation application page.</li>
			</ul>
		</div>
		<p class="result_txt" v-if="reserve_flg == false" v-html="resultTxt"></p>
		<a href="/eng/miyagikyo/reservation" class="goToLink" v-if="reserveErr == false">Return to the top page</a>
		<router-link tag="a" :to="{path: '/eng/miyagikyo/reservation', query: { date: selectedDate }}" class="goToLink" v-if="reserveErr == true && mygflg == true">Return to date and<br class="sp"> time selection.</router-link>
		<router-link tag="a" :to="{path: '/eng/yoichi/reservation', query: { date: selectedDate }}" class="goToLink" v-if="reserveErr == true && mygflg == false">Return to date and<br class="sp"> time selection.</router-link>
		<Tel />
	</div>
</template>

<script>
import $ from 'jquery';
import Tel from './EngTel'

export default {
	name: 'Result',
	components: {
		Tel
	},
	data () {
		return {
			plant_id: localStorage.getItem('plantId'),
			plantName: null,
			complementName: null,
			mygflg: false,
			selectedDate: null,
			errors: JSON.parse(localStorage.getItem('errs')),
			reserveErr: false,
			err_flg: false,
			ttlMessage: null,
			resultTxt: null,
			reserve_flg: false,
			password: localStorage.getItem('password')
		}
	},
	head: {
    title: function () {
      // 余市か宮城峡か判定
      if (location.pathname.indexOf("miyagikyo") != -1) {
        return {
					inner: 'RESERVATIONS｜MIYAGIKYO DISTILLERY｜NIKKA WHISKY',
					separator: ' ',
        }
      } else {
        return {
					inner: 'RESERVATIONS｜YOICHI DISTILLERY｜NIKKA WHISKY',
					separator: ' ',
        }
      }
		},
		meta: function () {
			return [
				{ property: 'og:locale', content: 'en_US' },
				{ property: 'og:site_name', content: 'NIKKA WHISKY' },
			]
		},
		link: [
      { rel: 'stylesheet', href: 'https://www.nikka.com/common/css/reset.css' },
      { rel: 'stylesheet', href: 'https://www.nikka.com/common/css/style.css' } 
    ],
    script: [
      { type: 'text/javascript', src: '/include/include.js' }
    ]
	},
	mounted() {
		// 一番上まで移動
		window.$("body, html").scrollTop(0);

		// リロード時余市か宮城峡か判定してそれぞれのトップに戻る
		if(localStorage.getItem('plantId') == null) {
			if(location.pathname.indexOf('miyagikyo') != -1) {
				this.$router.push({ path: '/eng/miyagikyo/reservation' });
			}else {
				this.$router.push({ path: '/eng/yoichi/reservation' });
			}
		}else if(localStorage.getItem('plantId') == 1000) {
			this.plantName = 'Miyagikyo Distillery';
		}else {
			this.plantName = 'Yoichi Distillery';
		}

		// トップに戻る先の変更デフォルトが宮城峡トップなのでpland_idが1001の場合は余市のトップをリンク先に設定する
		if(this.plant_id == 1000) {
			this.mygflg = true;
			this.selectedDate = localStorage.getItem('selectedDate');
		}else {
			$(".goToLink").attr('href', '/eng/yoichi/reservation');
		}

		if(location.pathname.indexOf('reservation/error') != -1) {
			this.reserveErr = true;
			this.complementName = 'Reservation was not possible';
			if(this.errors[0].errcode == 500999 || this.errors[0].errcode == 500001 || this.errors[0].errcode == 401000 || this.errors[0].errcode == 401010 || this.errors[0].errcode == 400010 || this.errors[0].errcode == 400075) {
				this.ttlMessage = 'Reservation was not possible<br>';
				this.resultTxt = 'due to capacity.';	
			}else if(this.errors[0].errcode == 400060 || this.errors[0].errcode == 400065) {
				this.ttlMessage = 'Sorry. Your desired course and time have reached capacity. Please consider another time.';
				this.resultTxt = 'Please consider another time.';
			}else {
				this.ttlMessage = 'Reservation was not possible';
				this.resultTxt = this.errors[0].message;
			}
		}else if(location.pathname.indexOf('thanks') != -1) {
			this.ttlMessage = 'Thank you for your<br class="sp"> reservation.<br>Your reservation has<br class="sp"> been accepted.';
			this.reserve_flg = true;
			this.complementName = 'Thank you for your reservation';
		}else if(location.pathname.indexOf('cancel') != -1) {
			this.ttlMessage = 'Cancel complete<br>We have accepted<br class="sp"> your reservation <br class="sp">cancellation';
			this.resultTxt = 'Thank you for contacting us. We look forward to your next reservation.';
			this.complementName = 'Cancel complete';
			localStorage.removeItem('password');
		}else {
			this.ttlMessage = '500 Internal Server Error';
			this.resultTxt = this.errors[0].message;
			this.complementName = 'Cancel failure';
		}
		// this.$emit('updateHead');

		if(this.reserveErr == false) {
			// それ以外のlocalStorageは全て外す
			localStorage.removeItem('courseInfo');
			localStorage.removeItem('selectCourseInfo');
			localStorage.removeItem('prefData');
			localStorage.removeItem('visitNum');
			localStorage.removeItem('comeWay');
			localStorage.removeItem('selectedDate');
			localStorage.removeItem('selectedTime');
			localStorage.removeItem('selectedEvent');
			// localStorage.removeItem('men');
			// localStorage.removeItem('women');
			localStorage.removeItem('adult_num');
			localStorage.removeItem('adult_num');
			localStorage.removeItem('minor_num');
			localStorage.removeItem('total_num');
			localStorage.removeItem('group_name');
			localStorage.removeItem('group_name_kana');
			localStorage.removeItem('companion_name');
			localStorage.removeItem('companion_name_kana');
			localStorage.removeItem('pref');
			localStorage.removeItem('tel');
			localStorage.removeItem('email');
			localStorage.removeItem('count');
			localStorage.removeItem('visit');
			localStorage.removeItem('unit');
			// localStorage.removeItem('note');
			localStorage.removeItem('old_tel');
			localStorage.removeItem('resId');
			localStorage.removeItem('errs');
			localStorage.removeItem('error_code');
			localStorage.removeItem('allergy');
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.result {
		padding-top: 70px;
		&_ttl {
			font-size: 32px;
			text-align: center;
			font-weight: 300;
			margin-bottom: 52px;
		}
		&_txt {
			font-size: 18px;
			text-align: center;
			line-height: 2 !important;
		}
		&_red {
			font-size: 18px;
			width: 600px;
			line-height: 2 !important;
			margin: 10px auto 40px;
			list-style: none;
			color: #DF0202;
		}
	}

	// 768以下
	.picxel .result {
		padding-top: 40px;
		&_ttl {
			font-size: 22px;
			margin-bottom: 40px;
		}
		&_txt {
			font-size: 12px;
			width: calc(100% - 50px);
			margin: 0 auto;
		}
		&_red {
			font-size: 12px;
			margin: 20px auto 30px;
			width: calc(100% - 50px);
			li {
				margin-bottom: 5px;
			}
		}
	}

	.resultBox {
		width: calc(100% - 100px);
		max-width: 840px;
		margin: 0 auto;
		&_careful {
			font-size: 18px;
			font-weight: bold;
			text-align: center;
			color: #DF0202;
		}
	}

	// 768以下
	.picxel .resultBox {
		width: calc(100% - 50px);
		&_careful {
			text-align: left;
			font-size: 15px;
		}
	}

	.win .mainFont .resultRice {
		font-size: 14px;
		line-height: 1.75 !important;
		margin-top: 52px;
		&_link {
			color: #AF9754;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}

	.picxel .resultRice {
		font-size: 12px;
		margin-top: 40px;
	}

	.resultList {
		list-style: none;
		border-top: 1px solid #E8E8E8;
		border-bottom: 1px solid #E8E8E8;
		margin-top: 24px;
		padding: 30px 20px;
		box-sizing: border-box;
		display: flex;
		&_ttl {
			position: relative;
			font-size: 18px;
			font-weight: bold;
			width: 212px;
			&::after {
				content: "：";
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
			}
		}
		&_txt {
			font-size: 18px;
			padding-left: 70px;
			width: calc(100% - 212px);
			box-sizing: border-box;
		}
	}

	// 768以下
	.picxel .resultList {
		width: 100%;
		border-top: 1px solid #E8E8E8;
		border-bottom: 1px solid #E8E8E8;
		margin-top: 24px;
		padding: 20px 0;
		align-items: center;
		&_ttl {
			font-size: 15px;
			width: 110px;
		}
		&_txt {
			width: calc(100% - 113px);
			font-size: 15px;
			padding-left: 15px;
			word-break: break-all;
		}
	}

	.explanList {
		list-style: none;
		margin-top: 24px;
		&_li {
			font-size: 18px;
		}
	}

	// 768以下
	.picxel .explanList {
		width: 100%;
		margin: 15px auto 0;
		&_li {
			font-size: 12px;
		}
	}

	.goToLink {
		display: block;
		text-decoration: none;
		font-size: 18px;
		width: 320px;
		text-align: center;
		line-height: 1 !important;
		margin: 40px auto 79px;
		background-color: #000;
		color: #fff;
		padding: 21px 0;
		cursor: pointer;
	}

	// 768以下
	.picxel .goToLink {
		font-size: 15px;
		width: calc(100% - 50px);
		margin: 50px auto 40px;
		padding: 21px 0;
	}

	.pc {
		display: block;
	}

	// 768以下
	.picxel .pc {
		display: none;
	}
</style>

