<template>
	<div class="num">
		<h2 class="num_ttl">ご予約番号の入力</h2>
		<p class="num_txt">
			「ご予約完了画面」及びご予約時にご登録いただいたメールアドレス宛にお送りしている<br class="pc">
			「確認メール」に掲載の『予約番号』と『電話番号』をご入力ください
		</p>
		<ul class="num_red">
			<li>※インターネットでのキャンセルは、見学日の前日の12時(正午)まで承ります。</li>
			<li>※以降、当日来場までの変更、キャンセルはお電話にて承ります。なお、時間変更は予約状況によりできない場合もございます。予めご了承願います。</li>
			<!-- <li>※WEBでのご予約内容の変更、キャンセルはご予約いただきました日付の3日前まで承ります。それ以降の変更、キャンセルについてはお電話でのお問い合わせをお願いいたします。</li> -->
		</ul>
		<form action="/check" method="post" class="checkForm">
			<div class="inputBox">
				<p class="inputBox_ttl">予約番号（半角英数字）</p>
				<input type="text" name="res_num" id="password" class="inputBox_input">
			</div>
			<div class="inputBox">
				<p class="inputBox_ttl">電話番号（半角）</p>
				<input type="text" name="number" id="number" class="inputBox_input">
			</div>
			<button type="button" class="checkForm_btn" @click="checkReservation()">予約内容の確認</button>
		</form>
		<router-link tag="a" to="/miyagikyo/reservation" class="goToLink" v-show="mygflg == true">トップページへ戻る</router-link>
		<router-link tag="a" to="/yoichi/reservation" class="goToLink" v-show="mygflg == false">トップページへ戻る</router-link>
		<Tel />
		<div class="errModal">
			<div class="errCont">
				<h2 class="errCont_ttl">エラー</h2>
				<p id="errTxt" class="errCont_txt"></p>
				<p class="errCont_txt">ご予約番号とご登録いただきましたお電話番号をご確認の上、<br>再度ご入力をお願いいたします。</p>
				<button class="errCont_close" @click="modalClose()">閉じる</button>
			</div>
			<div class="errModal_shadow" @click="modalClose()"></div>
		</div>
	</div>
</template>

<script>
import Tel from './Tel'
import moment from 'moment'
import axios from 'axios'

export default {
	name: 'ReservationInput',
	components: {
		Tel
	},
	data () {
		return {
			url: "/api/reserve/get",
			plantName: null,
			inputNum: null,
			inputTel: null,
			resData: null,
			resCourse: null,
			courseInfo: null,
			selectCourseInfo: null,
			error: null,
			scrollPos: 0,
			mygflg: false,
			availableDates: null,
			targetDate: null,
			prefData: null,
			visitNum: null,
			comeWay: null,
			paidInfo: false,
			selectAvailable: null
		}
	},
	head: {
    title: function () {
      // 余市か宮城峡か判定
      if (location.pathname.indexOf("miyagikyo") != -1) {
        return {
					inner: '見学予約申込 | 宮城峡蒸溜所 | NIKKA WHISKY',
					separator: ' ',
        }
      } else {
        return {
					inner: '見学予約申込 | 余市蒸溜所 | NIKKA WHISKY',
					separator: ' ',
        }
      }
    },
		// link: [
    //   { rel: 'stylesheet', href: 'https://www.nikka.com/common/css/reset.css' },
    //   { rel: 'stylesheet', href: 'https://www.nikka.com/common/css/style.css' } 
    // ],
    // script: [
    //   { type: 'text/javascript', src: '/include/include.js' }
    // ]
	},
	mounted() {
		// 宮城峡か否か判定する
		if(location.pathname.indexOf('miyagikyo') != -1 || localStorage.getItem('plantId') == 1000) {
			this.mygflg = true;
			this.plantName = '宮城峡蒸溜所';
		}else {
			this.plantName = '余市蒸溜所';
		}
		this.$emit('updateHead');
	},
	methods: {
		checkReservation() {
			// ボタンを一回しか押せないようにする
			window.$('.checkForm_btn').prop('disabled',true);

			// 入力欄に入力されたものの取得
			this.inputNum = window.$("#password").val();
			this.inputTel = window.$("#number").val();

			axios({
				method: "post",
				url: this.url,
				timeout: 500000,
				headers: {
					"X-NIKKA-AUTHORIZATION": "821eb2db4cf4210d72f8e2eb78736dc7"
				},
				data: {
					telno: this.inputTel,
					password: this.inputNum
				}
			}).then(res => {
				this.resData = res.data.data;
				// 予約内容データの格納
				localStorage.setItem('old_tel', this.inputTel);
				localStorage.setItem('password', this.inputNum);
				localStorage.setItem('resId', this.resData.reservation_id);
				localStorage.setItem('plantId', this.resData.plant_id);
				localStorage.setItem('course_id', this.resData.course_id);
				localStorage.setItem('selectedDate', this.resData.date);
				localStorage.setItem('selectedTime', this.resData.time);
				localStorage.setItem('group_name', this.resData.group_name);
				localStorage.setItem('group_name_kana', this.resData.group_name_kana);
				// if(this.mygflg == false) {
				// 	localStorage.setItem('note', this.resData.represent_note);
				// }
				localStorage.setItem('pref', this.resData.start_place_cd);
				localStorage.setItem('tel', this.resData.represent_tel);
				localStorage.setItem('email', this.resData.vst_mail_address);
				localStorage.setItem('companion_name', this.resData.companion_name);
				localStorage.setItem('companion_name_kana', this.resData.companion_name_kana);
				localStorage.setItem('count', this.resData.vst_come_num_id);
				localStorage.setItem('total_num', this.resData.total_num);
				localStorage.setItem('adult_num', this.resData.adult_num);
				// localStorage.setItem('men', this.resData.adult_men_num);
				// localStorage.setItem('women', this.resData.adult_female_num);
				localStorage.setItem('minor_num', this.resData.minor_num);
				localStorage.setItem('visit', this.resData.come_way_cd);
				localStorage.setItem('unit', this.resData.come_way_num);
				
				//202408
				localStorage.setItem('non_alcohol_flg', this.resData.non_alcohol_flg);
				localStorage.setItem('non_alcohol_num', this.resData.non_alcohol_num);
				localStorage.setItem('alcohol_num', this.resData.alcohol_num);

				// イベント情報も取得しておく
				axios({
					method: "post",
					url: "/api/reserveSlot/list",
					headers: {
						"X-NIKKA-AUTHORIZATION": "821eb2db4cf4210d72f8e2eb78736dc7"
					},
					data: {
						plant_id: this.resData.plant_id,
						display_start_date: moment(new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })).format("YYYY-MM")
					}
				}).then(res => {
					// 予約してある日を元にデータを探す
					this.resCourse = res.data.data;
					this.availableDates = this.resCourse.date_info;
					this.courseInfo = this.resCourse.course_info;
					this.prefData = this.resCourse.departure;
					this.visitNum = this.resCourse.vst_come_num;
					this.comeWay = this.resCourse.come_way;
					// 冬季期間のデータ
					this.winter = this.resCourse.plant_info;

					// コース情報をローカルストレージに保存
					localStorage.setItem('courseInfo', JSON.stringify(this.courseInfo));
					
					this.targetDate = this.availableDates.find((v) => v.date === this.resData.date);

					for(var i = 0; i < this.targetDate.rsv_slot.length; i++) {
						if(this.targetDate.rsv_slot[i].start_time == this.resData.time) {
							for(var j = 0; j < this.targetDate.rsv_slot[i].rsv_course.length; j++) {
								if(this.targetDate.rsv_slot[i].rsv_course[j].rsv_course_id == this.resData.course_id) {
									this.selectEvent = this.targetDate.rsv_slot[i].rsv_course[j]
								}
							}
						}
					}

					// 有料イベントかどうか
					if(this.selectEvent.rsv_course_type_name.indexOf('有料') != -1) {
						this.paidInfo = true;
					}

          // 緊急事態宣言が出ている地域は住所から除外する
          // if(this.resData.plant_id == 1000) {
          //   this.prefData = this.prefData.filter((pref) => {
          //     return !/北海道|茨城県|栃木県|群馬県|埼玉県|千葉県|東京都|神奈川県|新潟県|富山県|石川県|福井県|山梨県|長野県|岐阜県|静岡県|愛知県|三重県|滋賀県|京都府|大阪府|兵庫県|奈良県|和歌山県|鳥取県|島根県|岡山県|広島県|山口県|徳島県|香川県|愛媛県|高知県|福岡県|佐賀県|長崎県|長崎県|熊本県|大分県|宮崎県|鹿児島県|沖縄県/.test(pref["departure_name"]);
          //   });
          // }
					// API側の対応で必要なくなった
          // 海外は除外
          // this.prefData = this.prefData.filter((pref) => {
          //   return pref["overseas_flg"] == 2;
          // });

					// コースの設定の移動手段に絞る
					for(var c = 0; c < this.courseInfo.length; c++) {
						if(this.courseInfo[c].course_id == this.resData.course_id) {
							if(this.courseInfo[c].condition_transportation != null) {
								this.courseInfo[c].condition_transportation = this.courseInfo[c].condition_transportation.split(',');
								for(var o = 0; o < this.courseInfo[c].condition_transportation.length; o++) {
									for(var y = 0; y < this.comeWay.length; y++) {
										if(this.comeWay[y].come_way_id == Number(this.courseInfo[c].condition_transportation[o])) {
											this.comeWay.splice(y, 1);
										}
									}
								}
							}
						}
					}
					// シャトルバス対応
					if(this.resData.plant_id == 1000) {
						var flg = 0;
						// シャトルバス運行json読み込み
						var openDay = this.resCourse.plant_info.pickup_bus_operating_day;
						for(var s = 0; s < openDay.length; s++) {
							if(openDay[s] == this.targetDate.date) {
								flg++;
							}
						}
						// jsonデータ内に日付がなかったらシャトルバス除外する
						if(flg == 0) {
							this.comeWay = this.comeWay.filter((come) => {
								return !/送迎バス（シャトルバス）/.test(come["come_way_name"]);
							});
						}
						// 移動手段絞る
						localStorage.setItem('comeWay', JSON.stringify(this.comeWay));
					}else {
						// 余市はそのまま
						localStorage.setItem('comeWay', JSON.stringify(this.comeWay));
					}

					// 今までのシャトルバス対応
					// if(this.plantId == 1000) {
					//   // 選択した日の月と日をつなげた数字を出す
					//   var selectNum = String(this.selectMonth) + String(("00" + this.selectDate).slice( -2 ));
					//   // 冬季期間終了日の先頭の0を消す
					//   this.winter.pickup_bus_winter_period_to = this.winter.pickup_bus_winter_period_to.replace(/^0+/, '');
					//   // 冬季期間中かどうか
					//   if(Number(selectNum) >= Number(this.winter.pickup_bus_winter_period_from) || Number(selectNum) <= Number(this.winter.pickup_bus_winter_period_to)) {
					//     // 冬季期間中ならばcomeWayからシャトルバスを除く
					//     for(var w = 0; w < this.comeWay.length; w++) {
					//       if(this.comeWay[w].come_way_name.indexOf('シャトルバス') != -1) {
					//         this.comeWay.splice(w, 1);
					//       }
					//     }
					//   }else {
					//     // 選択した日のdate_infoをとってくる
					//     for(var s = 0; s < this.availableDates.length; s++) {
					//       if(this.availableDates[s].date == this.selectedDate) {
					//         this.selectAvailable = this.availableDates[s];
					//       }				
					//     }
					//     // 選択した日が土日または祝日かどうか
					//     if(this.selectDay == '土' || this.selectDay == '日' || this.selectAvailable.holiday_flg == 2) {
					//       console.log('シャトルバスが出ます');
					//     }else {
					//       for(var a = 0; a < this.comeWay.length; a++) {
					//         if(this.comeWay[a].come_way_name.indexOf('シャトルバス') != -1) {
					//           this.comeWay.splice(a, 1);
					//         }
					//       }
					//     }
					//   }
					// }

					this.selectCourseInfo = this.courseInfo.filter((array) => {
						return array["course_id"] == this.selectEvent.rsv_course_id;
					});

					// ローカルストレージに値をセット
					localStorage.setItem('prefData', JSON.stringify(this.prefData));
					localStorage.setItem('visitNum', JSON.stringify(this.resCourse.vst_come_num));
					localStorage.setItem('selectedEvent', JSON.stringify(this.selectEvent));
					localStorage.setItem('selectCourseInfo', JSON.stringify(this.selectCourseInfo));
					if(this.selectCourseInfo[0].chk_allergy_flg == 1) {
						localStorage.setItem('allergy', this.resData.allergy_flg);
					}
					// 予約内容確認ページに移動する
					if(localStorage.getItem('plantId') == 1000) {
						this.$router.push({ path: '/miyagikyo/reservation/reserveconfirm' });
					}else {
						this.$router.push({ path: '/yoichi/reservation/reserveconfirm' });
					}
				});
			}).catch(err => {
				this.error = err.response.data.data;
				window.$("#errTxt").text("認証に失敗しました");
				// モーダルを表示
				this.scrollPos = window.$(window).scrollTop();
				window.$("body").addClass("fixed").css({
					top: -this.scrollPos
				});
				
				// 処理が終わったらボタン元に戻す
				window.$('.checkForm_btn').prop('disabled',false);
				
				window.$(".errModal").fadeIn();
			});
		},

		modalClose() {
			// モーダル非表示
			window.$("body").removeClass("fixed").css({
				top: 0
			});
			window.scrollTo(0, this.scrollPos);
			window.$(".errModal").fadeOut();
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
	.num {
		padding-top: 70px;
		&_ttl {
			font-size: 32px;
			text-align: center;
			font-weight: 300;
			margin-bottom: 52px;
		}
		&_txt {
			font-size: 18px;
			text-align: center;
			line-height: 2 !important;
		}
		&_red {
			font-size: 18px;
			width: 600px;
			line-height: 2 !important;
			margin: 10px auto 40px;
			list-style: none;
			color: #DF0202;
		}
	}

	// 768以下
	.picxel .num {
		padding-top: 40px;
		&_ttl {
			font-size: 22px;
			margin-bottom: 40px;
		}
		&_txt {
			font-size: 12px;
			width: calc(100% - 50px);
			margin: 0 auto;
		}
		&_red {
			font-size: 12px;
			margin: 20px auto 30px;
			width: calc(100% - 50px);
			li {
				margin-bottom: 5px;
			}
		}
	}

	.checkForm {
		width: 500px;
		margin: 0 auto 50px;
		background-color: #F8F8F8;
		padding: 40px 67px 50px;
		box-sizing: border-box;
		&_btn {
			width: 100%;
			font-size: 18px;
			line-height: 1 !important;
			letter-spacing: 0.07em;
			margin-top: 10px;
			padding: 14px 0;
			border: none;
			outline: none;
			color: #fff;
			background-color: #333333;
			cursor: pointer;
			&:disabled {
				background-color: #cccccc;
			}
		}
	}

	// 768以下
	.picxel .checkForm {
		width: 100%;
		padding: 25px 25px 40px;
		margin-bottom: 40px;
		&_btn {
			font-size: 15px;
			padding: 14px 0;
			margin-top: 10px;
			&:disabled {
				background-color: #cccccc;
			}
		}
	}

	.inputBox {
		margin-bottom: 20px;
		&_ttl {
			font-size: 16px;
			margin-bottom: 8px;
		}
		&_txt {
			font-size: 14px;
			letter-spacing: .05em;
			margin-top: 10px;
			color: #656565;
		}
		&_input {
			width: 100%;
			font-size: 16px;
			padding: 14px 10px;
			box-sizing: border-box;
			outline: none;
			border-radius: 0;
			border: 1px solid #DDDDDD;
		}
	}

	// 768以下
	.picxel .inputBox {
		margin-bottom: 20px;
		&_ttl {
			font-size: 12px;
			margin-bottom: 5px;
		}
		&_input {
			font-size: 16px;
			transform: scale(0.75);
			width: 133%;
			margin-left: -16%;
			padding: 18.62px 13.3px;
			border: 1px solid #DDDDDD;
		}
	}

	.checkErr {
		font-size: 16px;
		text-align: left;
		font-weight: bold;
		margin-top: 8px;
		letter-spacing: 0.05em;
		color: #df0202;
	}

	// 768以下
	.picxel .checkErr {
		font-size: 14px;
		margin-top: 8px;
	}

	.goToLink {
		display: block;
		text-decoration: none;
		font-size: 18px;
		width: 320px;
		text-align: center;
		line-height: 1;
		margin: 0 auto 79px;
		background-color: #AF9754;
		color: #fff;
		padding: 21px 0;
		cursor: pointer;
	}

	// 768以下
	.picxel .goToLink {
		font-size: 15px;
		width: calc(100% - 50px);
		margin-bottom: 40px;
		padding: 21px 0;
	}

	.pc {
		display: block;
	}

	// 768以下
	.picxel .pc {
		display: none;
	}

	.sp {
		display: none;
	}

	// 768以下
	.picxel .sp {
		display: block;
	}

	// エラーモーダル
	.errModal {
		// 初期非表示
		display: none;
		&_shadow {
			position: fixed;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba($color: #000, $alpha: 0.8);
			cursor: pointer;
			z-index: 99;
		}
	}

	.errCont {
		position: fixed;
		width: 600px;
		padding: 44px 0;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		box-sizing: border-box;
		background-color: #FFF;
		z-index: 999;
		&_ttl {
			font-size: 30px;
			line-height: 1.5 !important;
			text-align: center;
			color: #DF0202;
			margin-bottom: 9px;
		}
		&_txt {
			font-size: 18px;
			line-height: 1.8 !important;
			text-align: center;
		}
		&_close {
			display: block;
			width: 225px;
			font-size: 18px;
			color: #ADADAD;
			padding: 14px 0;
			border: 1px solid #ADADAD;
			text-align: center;
			margin: 21px auto 0;
			background: none;
			outline: none;
			cursor: pointer;
		}
	}

	// 768以下
	.picxel .errCont {
		width: calc(100% - 50px);
		padding: 34px 0;
		&_ttl {
			font-size: 24px;
			margin-bottom: 10px;
		}
		&_txt {
			font-size: 12px;
			width: calc(100% - 36px);
			margin: 0 auto;
		}
		&_close {
			width: 160px;
			font-size: 13px;
			padding: 13px 0;
			margin-top: 20px;
			border: 1px solid #ADADAD;
		}
	}

	#errTxt {
		width: 450px;
		margin: 0 auto;
	}

	// 768以下
	.picxel #errTxt {
		width: 100%;
		margin: 0;
	}
</style>
