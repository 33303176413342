<template>
    <header>
        <div class="inner">
            <a id="logo" :href="`https://www.nikka.com${engDir}`"><svg aria-label="NIKKA WWHISKY"><use xlink:href="#logo_text"></use></svg></a>
            <nav>
                <div class="nav_close" data-view="sp">
                    <span></span>
                    <span></span>
                </div>
                <ul class="nav_menu">
                    <li><a :href="`https://www.nikka.com${engDir}/brands/`">
                        <p class="hover_txt">OUR BRANDS</p>
                        <p data-type="jp">商品紹介</p>
                    </a></li>
                    <li><a :href="`https://www.nikka.com${engDir}/distilleries/`">
                        <p class="hover_txt">DISTILLERIES</p>
                        <p data-type="jp">蒸溜所</p>
                    </a></li>
                    <li><a :href="`https://www.nikka.com${engDir}/story/`">
                        <p class="hover_txt">STORY</p>
                        <p data-type="jp">ストーリー</p>
                    </a></li>
                    <li><a :href="`https://www.nikka.com${engDir}/about/`">
                        <p class="hover_txt">ABOUT US</p>
                        <p data-type="jp">会社案内</p>
                    </a></li>
                    <li><a :href="`https://www.nikka.com${engDir}/contact/`">
                        <p class="hover_txt">CONTACT US</p>
                        <p data-type="jp">お問い合わせ</p>
                    </a></li>
                </ul>

            </nav>
            <ul class="lang_btn">
                <template v-if="lang === 'jp'">
                    <li data-type="current"><a :href="pathName" class="language-switcher"><span>JP</span></a></li>
                    <li><a :href="langUrl" class="language-switcher"><span>EN</span></a></li>
                </template>
                <template v-else-if="lang === 'en'">
                    <li><a :href="langUrl" class="language-switcher"><span>JP</span></a></li>
                    <li data-type="current"><a :href="pathName" class="language-switcher"><span>EN</span></a></li>
                </template>
            </ul>
            <!-- <div class="search_btn" data-view="pc"><span></span></div> -->
            <div class="menu_btn" data-view="sp">
                <span></span>
                <span></span>
            </div>
        </div>

        <div class="top_logo">
            <svg data-type="emblem"><use xlink:href="#logo_emblem"></use></svg>
            <svg data-type="text" aria-label="NIKKA WWHISKY"><use xlink:href="#logo_text"></use></svg>
        </div>
    
        <div id="fb-root"></div>
    </header>
</template>

<script>
export default {
    name: "MainHeader",
    props: {
        lang: {
            type: String,
            default: 'jp',
            required: true
        }
    },
    data() {
        return {
            distilleryName: "",
            pathName: location.pathname,
            engDir: "",
            langUrl: "",
            pageType: ""
        };
    },
    mounted() {
        this.engDir = this.updatedHref();
        this.pathName = location.pathname;
        this.checkPageType(this.pathName);
        this.pageType = this.checkPageType(this.pathName);
        this.langUrl = this.createChangeLangUrl(this.pageType,this.pathName)
    },
    watch: {
        $route() {
            this.engDir = this.updatedHref();
            this.pathName = location.pathname;
            this.pageType = this.checkPageType(this.pathName);
            this.langUrl = this.createChangeLangUrl(this.pageType,this.pathName)
        }
    },
    methods: {
        updatedHref() {
            return this.lang === "en" ? "/en" : "";
        },
        checkPageType: function (_pathName) {
            if (_pathName.includes("miyagikyo/reservation")) {
                return "miyagikyo_reservationPage";
            } else if (_pathName.includes("yoichi/reservation")) {
                return "yoichi_reservationPage";
            } else {
                return "jp_eventPage";
            }
        },
        createChangeLangUrl: function (pageType, _pathName) {
            let _url;
            if (this.lang === "jp") { //日本語ページ
                if (pageType === "yoichi_reservationPage") {
                    if (_pathName.includes("yoichi/reservation/reserve")) {
                        _url = "/eng/yoichi/reservation/reserve";
                    } else {
                        _url = "/eng/yoichi/reservation/";
                    }
                } else if (pageType === "miyagikyo_reservationPage") {
                    if (_pathName.includes("miyagikyo/reservation/reserve")) {
                        _url = "/eng/miyagikyo/reservation/reserve";
                    } else {
                        _url = "/eng/miyagikyo/reservation/";
                    }
                } else { //eventページ
                    _url = "https://www.nikka.com/en/";
                }
            } else { //英語ページ
                if (pageType === "yoichi_reservationPage") {
                    if (_pathName.includes("yoichi/reservation/reserve")) {
                        _url = "/yoichi/reservation/reserve";
                    } else {
                        _url = "/yoichi/reservation/";
                    }
                } else if (pageType === "miyagikyo_reservationPage") {
                    if (_pathName.includes("miyagikyo/reservation/reserve")) {
                        _url = "/miyagikyo/reservation/reserve";
                    } else {
                        _url = "/miyagikyo/reservation/";
                    }
                }
            }
            return _url;
        }
    }
};
</script>
